import React, {  useEffect } from "react";

const LogoutClaveunica = () => {
 
    
   
    
    useEffect(() => {
      localStorage.removeItem("token");
      if (localStorage.getItem("token") === null){
        window.location.href = "/";
      }
    }, []);
    
  
    return (
  
      <div className="px-4 py-5 my-5 text-center">
        <h1 className="">
          Cerrando la Sesión
        </h1>
        <button className="btn btn-warning" type="button" disabled>
          <span
            className="spinner-border spinner-border-sm"
            role="status"
            aria-hidden="true"
          ></span>
          Redireccionando...
        </button>
      </div>
    );
}

export default LogoutClaveunica