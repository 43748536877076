import React from "react";

const PeriodoCerrado = () => {
  return (
    <div className="alert alert-warning" role="alert">
      <h2> Estimados usuarios </h2>
      <p>
        Comunicamos que el proceso masivo de devolución de cotizaciones pagadas
        en exceso(DPE) ha finalizado.
      </p>
      <p>Fondo Nacional de Salud</p>
    </div>
  );
};

export default PeriodoCerrado;
