import React from "react";
import useAppContext from "../hooks/useAppContext";
import { formatNumber, formatearCantidad, formatoPeriodo } from "../helpers/formateaRut";

const ListadoPropuesta = () => {
  const { dataUsuarioPropuesta, montoTotalUsuario } = useAppContext();
  
  

  return (
    <div>
      <div className="table table-responsive">
        <table className="table table-striped">
          <thead>
            <tr>
              <th className="text-center">Rut-Run</th>
              <th className="text-center">Motivo Devolución</th>
              <th className="text-center">Período</th>
              <th className="text-center">Remuneración</th>
              <th className="text-center">Cotización</th>
              <th className="text-center">Tope Renta</th>
              <th className="text-center">Tope Cotización</th>
              <th className="text-center">Devolución</th>
            </tr>
          </thead>

          <tbody>
            {dataUsuarioPropuesta.map((propuesta) => {
              return (
                <tr key={propuesta.id}>
                  <td className="text-center">
                    {formatNumber(propuesta.rut)}-{propuesta.dv}
                  </td>
                  <td className="text-center">{propuesta.tipoDevolucion}</td>
                  <td className="text-center">
                    {formatoPeriodo(propuesta.periodo)}
                  </td>
                 
                   { ( propuesta.montoRemuneracion== null || propuesta.montoRemuneracion=== "" )  && (
                    <td className="text-center">-</td>
                  )}
                  {(propuesta.montoRemuneracion !== "" && propuesta.montoRemuneracion != null) && (
                    <td className="text-center">
                      {" "}
                      {formatearCantidad(parseInt(propuesta.montoRemuneracion))}
                    </td>
                  )}
                    
                  {(propuesta.montoCotizacion === "" || propuesta.montoCotizacion ==null) && (
                    <td className="text-center">-</td>
                  )}
                  {(propuesta.montoCotizacion !== ""  && propuesta.montoCotizacion != null ) && (
                    <td className="text-center">
                      {" "}
                      {formatearCantidad(parseInt(propuesta.montoCotizacion))}
                    </td>
                  )}
                  
                  {(propuesta.topeRenta === "" || propuesta.topeRenta == null ) && (
                    <td className="text-center">-</td>
                  )}
                  {(propuesta.topeRenta !== "" && propuesta.topeRenta != null) && (
                    <td className="text-center">
                      {" "}
                      {formatearCantidad(parseInt(propuesta.topeRenta))}
                    </td>
                  )}
                   
                  {(propuesta.topeCotizacion === "" || propuesta.topeCotizacion == null )&& (
                    <td className="text-center">-</td>
                  )}
                  {(propuesta.topeCotizacion !== "" && propuesta.topeCotizacion != null )&& (
                    <td className="text-center">
                      {" "}
                      {formatearCantidad(parseInt(propuesta.topeCotizacion))}
                    </td>
                  )}
                
                  {propuesta.montoDevProceso === "" && (
                    <td className="text-center">-</td>
                  )}
                  {propuesta.montoDevProceso !== "" && (
                    <td className="text-center">
                      {" "}
                      {formatearCantidad(parseInt(propuesta.montoDevProceso))}
                    </td>
                  )}
                </tr>
              );
            })}
          </tbody>

          <tfoot className="ultimo">
            <tr>
              <td colSpan="6" className=" ml-2">
               
              </td>
              <td className="text-center">
                <strong>TOTAL</strong>
              </td>
              {montoTotalUsuario === "" && <td className="text-center">-</td>}
              {montoTotalUsuario !== "" && (
                <td className="text-center fs-5">
                  <strong>
                    {" "}
                    {formatearCantidad(parseInt(montoTotalUsuario))}
                  </strong>
                </td>
              )}
            </tr>
          </tfoot>
        </table>
      </div>
    </div>
  );
};

export default ListadoPropuesta;
