import html2pdf from "html2pdf.js";
import { jsPDF } from "jspdf";
import { formatearCantidad, formatNumber, formatoPeriodoNombre } from "../helpers/formateaRut";
import useAppContext from "../hooks/useAppContext";
import LogoF from "../img/logofonasa.png";

const BotonPdf = ({ dataComprobante, respuesta }) => {
  const { infoPersona } = useAppContext();

  const { nombre, apellPater, apellMater, run, dv, periodoDPE } = infoPersona;

  const { email, estado, folio, montoDevolucion, tipoPago } = dataComprobante;

  const date = new Date();

  let tipoSolicitud = "";
  let monto = "";
  let estimado = "";

  if (apellMater === undefined && apellPater === undefined) {
    estimado = nombre;
  } else {
    estimado = `${nombre} ${apellPater} ${apellMater}`;
  }

  if (estado === "3") {
    tipoSolicitud = "Devolución de Pago en Excesos";
    monto = formatearCantidad(parseInt(montoDevolucion));
  } else {
    tipoSolicitud = "Revisión DPE";
    monto = "En Revisión";
  }

  const handlePdf = () => {
    const input = document.getElementById(respuesta).innerText;
    let doc = new jsPDF();
    doc.addImage(LogoF, "PNG", 15, 15, 45, 15);
    doc.setFont("helvetica", "bold");
    doc.setFontSize(12);
    doc.text(
      `Comprobante de Solicitud de ${tipoSolicitud}`,
      110,
      55,
      null,
      null,
      "center"
    );

    doc.setFont("helvetica", "bold");
    doc.setFontSize(12);
    doc.text(`Proceso DPE Masivo ${formatoPeriodoNombre(periodoDPE)}`, 110, 60, null, null, "center");

    doc.setFont("helvetica", "bold");
    doc.setFontSize(12);
    doc.text(`Folio N°: ${folio}`, 110, 65, null, null, "center");

    doc.setFont("helvetica", "normal");
    doc.setFontSize(12);
    doc.text(`Estimado(a):`, 20, 85, null, null);

    doc.setFont("helvetica", "bold");
    doc.setFontSize(12);
    doc.text(`${estimado}`, 47, 85, null, null);

    doc.setFont("helvetica", "normal");
    doc.setFontSize(12);
    doc.text(`RUN-RUT:`, 20, 92, null, null);

    doc.setFont("helvetica", "bold");
    doc.setFontSize(12);
    doc.text(`${formatNumber(run)}-${dv}`, 43, 92, null, null);

    doc.setFont("helvetica", "normal");
    doc.setFontSize(12);
    doc.text(`Monto Devolución:`, 20, 98, null, null);

    doc.setFont("helvetica", "bold");
    doc.setFontSize(12);
    doc.text(`${monto}`, 57, 98, null, null);

    doc.setFont("helvetica", "normal");
    doc.setFontSize(12);
    doc.text(`Fecha Solicitud:`, 20, 104, null, null);

    doc.setFont("helvetica", "bold");
    doc.setFontSize(12);
    doc.text(`${date.toLocaleDateString()}`, 53, 104, null, null);

    doc.setFont("helvetica", "normal");
    doc.setFontSize(12);
    doc.text(input, 20, 120, null, null);

    doc.setFont("helvetica", "bold");
    doc.setFontSize(12);
    doc.text(
      `Ante consultas, puede llamar al Call Center de FONASA 600 360 3000.`,
      105,
      260,
      null,
      null,
      "center"
    );

    doc.output("dataurlnewwindow", { filename: "comprobante.pdf" });
  };
  return (
    <>
      <button className="btn btn-primary" type="button" onClick={handlePdf}>
        Descargar comprobante
      </button>
    </>
  );
};

export default BotonPdf;
