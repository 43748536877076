export const validarEmail = (email) => {
  let errores = "";

  if (!email) {
    errores = "Este campo es obligatorio";
  } else if (!/^[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+\.[a-zA-Z0-9-.]+$/.test(email)) {
    errores = "El correo ingesado no es valido";
  }

  return errores;
};
export const validarConfirmarEmail = (email, confirmarEmail) => {
  let errores = "";

  if (!confirmarEmail) {
    errores = "Este campo es obligatorio";
  } else if (
    !/^[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+\.[a-zA-Z0-9-.]+$/.test(confirmarEmail)
  ) {
    errores = "El correo ingesado no es valido";
    console.log(errores);
  } else if (email !== confirmarEmail) {
    errores = "El correo de confirmación debe ser igual al email de contacto";
  }

  return errores;
};

export const validarTelefono = (telefono) => {
  let errores = "";

  if (!telefono) {
    errores = "Este campo es obligatorio";
  } else if (!/^\d{7,14}$/.test(telefono)) {
    errores = "El telëfono debe contener solo números";
  }

  return errores;
};

export const resetApp = () => {
  localStorage.removeItem("run");
  localStorage.removeItem("periodoDPE");
  localStorage.removeItem('_grecaptcha')
  localStorage.removeItem("serieRun")
  localStorage.removeItem("tipoDePersona")
  localStorage.removeItem("estadoSolicitud")
  localStorage.removeItem("estadoPropuesta")
  localStorage.removeItem("token")
  localStorage.removeItem("usuarioValido");
  window.location.reload();
};
