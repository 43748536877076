import React, { createContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

const AppContext = createContext();

const AppProvider = ({ children }) => {
  const navigate = useNavigate();
  //*STATE INFORMACIÓN USUARIO
  const [dataUsuarioPropuesta, setDataUsuarioPropuesta] = useState([]);
  const [infoPersona, setInfoPersona] = useState({
    nombre: "",
    apellPater: "",
    apellMater: "",
    run: "",
    dv: "",
    periodoDPE: "",
  });
  const [tipoPersona, setTipoPersona] = useState("");
  const [montoTotalUsuario, setMontoTotalUsuario] = useState("");
  const [periodos, setPeriodos] = useState({});
  const [dataComprobanteRevision, setDataComprobanteRevision] = useState([]);
  const [dataComprobante, setDataComprobante] = useState([]);
  const [respuestaNumSerie, setRespuestaNunSerie] = useState(false);
  const [estadoPropuesta, setEstadoPropuesta] = useState("");
  const [estadoSolicitud, setEstadoSolicitud] = useState("");

  //*STATE INFORMACIÓN GENERAL
  const [peridoInicial, setPeriodoInicial] = useState("");
  const [calendario, setCalendario] = useState({});
  const [cargando, setCargando] = useState(true);
  const [dataRegion, setDataRegion] = useState([]);
  const [dataComuna, setDataComuna] = useState([]);
  const [dataBancos, setDataBancos] = useState([]);
  const [dataCuentas, setDataCuentas] = useState([]);
  const [errorServicio, setErrorServicio] = useState(null);
  const [usuarioValido, setUsuarioValido] = useState(false);
  const [tokenCue, setTokenCue] = useState("");
  //const tokenCue_provisional =
 //"Bearer eyJhbGciOiJIUzUxMiIsInR5cCI6IkpXVCJ9.eyJpYXQiOjE3MTEzNzkzMTEsImV4cCI6MTcxOTE1NTMxMSwic3ViIjoiMjY0NTUyNDEtOSIsImp0aSI6IlByb3h5LVNlYy1CZW5lZmljaWFyaW8iLCJhdXRob3JpdGllcyI6WyJST0xFX1VTRVIiXX0.YkKRWRdkjTcZA0SoOjFhNXEqSMwUgWnMwbLkcqqTkMk4h2OiaxhjraZztYpBInd3CaiiMblaIMBI_xTCK773Jg";
  //const [tokenCue, setTokenCue] = useState(tokenCue_provisional);
  const [rutCue, setRutCue] = useState("");

    
 // useEffect(() => {
   // const token =localStorage.getItem("token")
    //if (token) {
      //console.log("aqui token",token)
    // decodeToken(token);
    //  await dpeexi(rutCue);
      
   // //  window.location.href = process.env.REACT_APP_REDIRECT
   // }
 // }, []);

  //* DECODIFICAR TOKEN
 

  const decodeToken = async (tokenc) => {
  
    const URL = `${process.env.REACT_APP_WS_DECODE_TOKEN}`;

    const requestOptions = {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: tokenc,
      },
    };
    const response = await fetch(URL, requestOptions);
    const data = await response.json();
    console.log("data",data.sub);
    setRutCue(data.sub);
    console.log("estiy aqui", rutCue);
    await dpeexi(data.sub);
   return;   
};

const dpeexi = async (rutCue) => {
        
  const rutSinDv = rutCue.substring(0, rutCue.length - 1).replace(/\D/g, "");
  console.log("quiero estar aqui",rutSinDv)
  localStorage.setItem("run", rutSinDv);
  console.log(peridoInicial)
 
  const periodo =localStorage.getItem("periodoDPE")
  const URL = `${process.env.REACT_APP_WS_DEP}/existedpe/${periodo}/${rutSinDv}`;

  const resp = await fetch(URL);
  const data = await resp.json();
  console.log("probaaaaando",data);
  if(data.length === 0){
  
    console.log("paginanodpe",)
    navigate("/paginanodpe");
     return
  }
  const run = localStorage.getItem("run");
  const beneficiarioExiste = await getPropuestaIngresada(
    data[0].rut,
    data[0].periodoDPE,
    data.length,
    console.log("veamos",data.length)
  );
  
 
  if (beneficiarioExiste.length) {
    localStorage.setItem("estadoSolicitud", beneficiarioExiste[0].estado);
    localStorage.setItem(
      "estadoPropuesta",
      JSON.stringify(beneficiarioExiste[0])
      );

  }
 

      if (
        beneficiarioExiste.length  &&
        (beneficiarioExiste[0].estado === "2" ||
          beneficiarioExiste[0].estado === "3")
      ) {
        localStorage.removeItem("estadoSolicitud");
        localStorage.removeItem("estadoPropuesta");
          navigate("/completadoproceso");
        
        return;
      }
 if (data[0]?.rut === run){
  await getDataUsuario(data);
  console.log("probaaaaandosi llego",);
  navigate("/propuesta");
  return;
}
 
return data;
//window.location.href = process.env.REACT_APP_REDIRECT
 };
 // *GET INFORMACIÓN PERIODO ACTUAL
 const getPeridoInicial = async () => {
  try {
    const URL = `${process.env.REACT_APP_WS_PERIODO_DPE}`;
    const resp = await fetch(URL);
    const data = await resp.json();
    localStorage.setItem("periodoDPE", data);
    setPeriodoInicial(data);
    console.log("probando algo",data)
    setErrorServicio(false);
    if (data) {
      await getDataUsuario(data);
      await getCalendario(data);
    }
  } catch (error) {
    console.log("ERROR EN SERVICIO AL RECUPERAR EL PERIODO", error);
    setCargando(false);
    setErrorServicio(true);
    setTimeout(() => {
      setErrorServicio(false);
    }, 1500);
    console.log("getPeridoInicial", errorServicio);
  }
};


// logout cue

const logoutCue = () => {
  
  localStorage.removeItem("run");
  localStorage.removeItem("tokenc");
  localStorage.removeItem("periodoDPE");
  window.location.href = process.env.REACT_APP_WS_LOGOUT;
  //if (localStorage.getItem("token") === null) {
    //navigate("/");
    //window.location.reload();
  //}

};


  //*GET FECHA CALENDARIO
  const getCalendario = async (peridoInicial) => {
    try {
      const URL = `${process.env.REACT_APP_WS_OBTENER_CALENDARIO}/${peridoInicial}`;
      const resp = await fetch(URL);
      const data = await resp.json();
      if (data) {
        setCalendario(data);
        setErrorServicio(false);
      }
    } catch (error) {
      console.log("ERROR EN SERVICIO AL RECUPERAR EL CALENDARIO", error);
      // setCalendario({})
      setCargando(false);
      setErrorServicio(true);
      setTimeout(() => {
        setErrorServicio(false);
      }, 1500);
      console.log("getCalendario", errorServicio);
    }
  };

  //*SET INFORMACIÓN USUARIO
  useEffect(() => {
    dataUsuarioPropuesta?.forEach((ele, index) => {
      setInfoPersona({
        nombre: ele.nombre,
        apellPater: ele.apelPater,
        apellMater: ele.apelMater,
        run: ele.rut,
        dv: ele.dv,
        periodoDPE: peridoInicial,
      });
    });
  }, [dataUsuarioPropuesta]);

  //* GET INFORMACIÓN PROPUESTA
  const getDataUsuario = async (peridoInicial) => {
    const run = localStorage.getItem("run");
    const serieRun = localStorage.getItem("serieRun");
    const tipoP = localStorage.getItem("tipoDePersona");
    const catpcha = localStorage.getItem("usuarioValido");
    console.log("probando perido dd",peridoInicial)
    console.log("probando run aqui ddd",run)
    if (!run ) {
      setCargando(false);
      setErrorServicio(false);
      return;
    }
    try {
      const periodo =localStorage.getItem("periodoDPE")
      const run = localStorage.getItem("run");
      console.log("probando perido",peridoInicial)
      console.log("probando run aqui",run)
      const URL = `${process.env.REACT_APP_WS_EXISTE_DPE}/${periodo}/${run}`;
      const resp = await fetch(URL);
      const data = await resp.json();
      setDataUsuarioPropuesta(data);
      setEstadoSolicitud(localStorage.getItem("estadoSolicitud"));
      setEstadoPropuesta(JSON.parse(localStorage.getItem("estadoPropuesta")));

      if (run) {
        navigate("/propuesta");
        console.log("AQUI");
      }
    } catch (error) {
      setDataUsuarioPropuesta([]);
      console.log(error);
    }
    setCargando(false);
    setErrorServicio(false);
    // console.log("getDataUsuario", errorServicio);
  };

  //*CHEQUEAR SI EL BENEFICIARIO TIENE PROPUESTA EN PROCESO

  const getPropuestaIngresada = async (run, periodoDPE) => {
    try {
      const URL = `${process.env.REACT_APP_WS_LISTA_SOLICTUD}/${run}/${periodoDPE}`;
      const resp = await fetch(URL);
      const data = await resp.json();
      setEstadoPropuesta(data[0]);
      return data;
    } catch (error) {
      console.log("ERROR EN SERVICIO AL RECUPERAR PROPUESTA", error);
      setCargando(false);
      setErrorServicio(true);
      setTimeout(() => {
        setErrorServicio(false);
      }, 1500);
    }
  };

  //* GET DATA COMPROBANTE REVISION
  const getDataComprobanteRevision = async (run, periodoDPE) => {
    const URL = `${process.env.REACT_APP_WS_COMPROBANTE_REVISION}/${run}/${periodoDPE}`;
    const resp = await fetch(URL);
    const data = await resp.json();
    setDataComprobanteRevision(data);
    return data;
  };

  //* GET DATA COMPROBANTE PROPUESTA
  const getDataComprobante = async (run, periodoDPE) => {
    const URL = `${process.env.REACT_APP_WS_CONSULTA_ACEPTADAS}/${run}/${periodoDPE}`;
    const resp = await fetch(URL);
    const data = await resp.json();
    setDataComprobante(data);
    return data;
  };

  //*SUMATORIA MONTO PROPUESTA
  useEffect(() => {
    const sumaMontos = dataUsuarioPropuesta
      .map((item) => parseInt(item.montoDevProceso))
      .reduce((prev, curr) => prev + curr, 0);
    setMontoTotalUsuario(sumaMontos);
  }, [dataUsuarioPropuesta]);

  //*CONSULTAR PERIODO MAX Y MIN DE REVISION
  const getFechaPerido = async (periodo) => {
    const URL = `${process.env.REACT_APP_WS_CALCULO_FECHAS}/${periodo}`;
    const resp = await fetch(URL);
    const data = await resp.json();
    setPeriodos(data);
  };

  //*GET INFORMACIÓN REGIÓN

  const getDataRegiones = async () => {
    try {
      const URL = `${process.env.REACT_APP_WS_DEP}/listaregiones`;
      const resp = await fetch(URL);
      const data = await resp.json();
      const regionOrdenada = data.sort((a, b) => {
        return a.codigoSecundario - b.codigoSecundario;
      });
      setDataRegion(regionOrdenada);
      setErrorServicio(false);
    } catch (error) {
      console.log("ERROR EN SERVICIO AL RECUPERAR LAS REGIONES", error);
      setCargando(false);
      setErrorServicio(true);
      setTimeout(() => {
        setErrorServicio(false);
      }, 1500);
      console.log("Regiones", errorServicio);
    }
  };

  //*GET INFORMACIÓN COMUNAS
  const getDataComunas = async (region) => {
    try {
      const URL = `${process.env.REACT_APP_WS_DEP}/listacomuna/${region}`;
      const resp = await fetch(URL);
      const data = await resp.json();
      setDataComuna(data);
      setErrorServicio(false);
    } catch (error) {
      console.log("ERROR EN SERVICIO AL RECUPERAR LAS COMUNAS", error);
      setCargando(false);
      setErrorServicio(true);
      setTimeout(() => {
        setErrorServicio(false);
      }, 1500);
      console.log("Comunas", errorServicio);
    }
  };

  //*GET INFORMACIÓN BANCOS
  const getDataBancos = async () => {
    try {
      const URL = `${process.env.REACT_APP_WS_DEP}/listabancos`;
      const resp = await fetch(URL);
      const data = await resp.json();
      setDataBancos(data);
      setErrorServicio(true);
    } catch (error) {
      console.log("ERROR EN SERVICIO AL RECUPERAR LOS BANCOS", error);
      setCargando(false);
      setErrorServicio(true);
      setTimeout(() => {
        setErrorServicio(false);
      }, 1500);
      console.log("BAncos", errorServicio);
    }
  };

  //*GET TIPO CUENTA
  const getDataCuentas = async () => {
    try {
      const URL = `${process.env.REACT_APP_WS_DEP}/listacuentas`;
      const resp = await fetch(URL);
      const data = await resp.json();
      setDataCuentas(data);
      setErrorServicio(false);
    } catch (error) {
      console.log("ERROR EN SERVICIO AL RECUPERAR LOS TIPO DE CUENTA", error);
      setCargando(false);
      setErrorServicio(true);
      setTimeout(() => {
        setErrorServicio(false);
      }, 1500);
      console.log("Cuentas", errorServicio);
    }
  };

  useEffect(() => {
    getDataRegiones();
    getDataBancos();
    getDataCuentas();
    getPeridoInicial();
  }, []);

  return (
    <AppContext.Provider
      value={{
        peridoInicial,
        dataUsuarioPropuesta,
        infoPersona,
        montoTotalUsuario,
        periodos,
        tokenCue,
        rutCue,
        decodeToken,
        dpeexi,
        logoutCue,
        dataRegion,
        dataComuna,
        dataBancos,
        dataCuentas,
        cargando,
        dataComprobanteRevision,
        dataComprobante,
        errorServicio,
        tipoPersona,
        calendario,
        setTipoPersona,
        setErrorServicio,
        getFechaPerido,
        setDataUsuarioPropuesta,
        getDataComunas,
        getPropuestaIngresada,
        setCargando,
        setDataComprobanteRevision,
        getDataComprobanteRevision,
        getDataComprobante,
        setRespuestaNunSerie,
        estadoPropuesta,
        estadoSolicitud,
        usuarioValido,
        setUsuarioValido,
        setEstadoPropuesta,
        setEstadoSolicitud,
      }}
    >
      {children}
    </AppContext.Provider>
  );
};

export { AppProvider };

export default AppContext;
