import { Link } from "react-router-dom";
import logofonasa1 from "../img/logofonasa1.svg";

function Header() {
  return (
    <>
      <div className="navbar navbar-light pt-4 bg-primary mb-5 header_fonasa">
        <div className="container">
          <div className="row ">
            <Link to={"/"} className="">
              <img className='img-header' src={logofonasa1} alt="" />
            </Link>
          </div>

          <hr />
        </div>
      </div>
    </>
  );
}
export default Header;