import { useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import ReCAPTCHA from "react-google-recaptcha";
import Alerta from "../components/Alerta";
import Spinner from "../components/Spinner";
import {
  bloqueoPegarCopiar,
  validador,
  validaRunSerie,
  validaRutEmpresa,
  valideKey,
} from "../helpers/validaRut";
import useAppContext from "../hooks/useAppContext";
import rud from "../img/rud.png";
import ruta from "../img/ruta.png";
import { resetApp } from "../helpers/validaInput";
import PeriodoCerrado from "../components/PeriodoCerrado";
import LoginClaveunica from "../components/LoginClaveunica";

const Prelogin = () => {
  const {
    setDataUsuarioPropuesta,
    getPropuestaIngresada,
    peridoInicial,
    rutCue,
    cargando,
    calendario,
    setCargando,
    tipoPersona,
    setTipoPersona,
    setErrorServicio,
    setEstadoPropuesta,
    setEstadoSolicitud,
    usuarioValido, setUsuarioValido
  } = useAppContext();

  const [rut, setRut] = useState("");
  const [numeroSerie, setNumeroSerie] = useState("");
  const [getDpe, setGetDpe] = useState(false);
  const [alerta, setAlerta] = useState({});
  // const [usuarioValido, setUsuarioValido] = useState(false);

  const navigate = useNavigate();
  let btnLimpiar = false;
  const rutSinDv = rut.substring(0, rut.length - 1).replace(/\D/g, "");
  const dvRut = rut.substring(rut.length - 1);

  const captcha = useRef(null);

  const limpiar = () => {
    setRut("");
    setAlerta({});
    setGetDpe(false);
    setNumeroSerie("");
    localStorage.removeItem("run");
    localStorage.removeItem("_grecaptcha");
    localStorage.removeItem("serieRun");
    localStorage.removeItem("tipoDePersona");
    localStorage.removeItem("estadoSolicitud");
    localStorage.removeItem("estadoPropuesta");
    localStorage.removeItem("token");
  };

  const handleTipoPersona = ({ target }) => {
    setTipoPersona(target.value);
    localStorage.setItem("tipoDePersona", target.value);
  };

  let fechaCierre = calendario.fechaCierre;
  fechaCierre = new Date(fechaCierre).toLocaleDateString("es-CL", {
    year: "numeric",
    month: "long",
    day: "numeric",
  });

  const onChange = () => {
    if (captcha.current.getValue()) {
      setAlerta({});
      setUsuarioValido(true);
      localStorage.setItem('usuarioValido', usuarioValido)
    } else {
      setUsuarioValido(false);
    }
  };

  // *VALIDAR NÚMERO DE SERIE CARNET
  const validarSeriecarnet = () => {
    if (tipoPersona == 1 && [numeroSerie].includes("")) {
      setAlerta({
        msg: "Debe ingresar N° Serie Carnet",
        error: true,
      });
      return;
    }
    if (!usuarioValido) {
      setAlerta({
        msg: "Debe marcar la validación de No soy un Robot",
        error: true,
      });
      return;
    }

    const raw = {
      bodyResquest: {
        RUN: rutSinDv,
        DV: dvRut,
        nroSerieCedula: numeroSerie,
      },
    };

    setCargando(true);
    const request = {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify(raw),
    };
    validaRunSerie(request).then(
      (data) => {
        const respuesta = data.headerResponse.estadoID;
        if (respuesta !== 0) {
          setAlerta({
            msg: data.headerResponse.estadoMSG,
            error: true,
          });
          setTimeout(() => {
            setAlerta({});
          }, 6000);
          setCargando(false);
          return;
        } else {
          localStorage.setItem("serieRun", numeroSerie);
          navigate("/propuesta");

          setAlerta({});
          setCargando(false);
        }
      },
      function (e) {
        console.log(e);
        setCargando(false);
        setErrorServicio(true);
        setTimeout(() => {
          setErrorServicio(false);
        }, 1500);
      }
    );
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    if ([rut].includes("")) {
      setAlerta({
        msg: "Debe ingresar un RUN-RUT válido",
        error: true,
      });
      return;
    }

    const rutValido = validador(rut);
    const rutEmpresa = validaRutEmpresa(rut);

    if (tipoPersona == 2 && rutEmpresa) {
      setAlerta({
        msg: "El RUT ingresado no pertenece a una Persona Jurídica",
        error: true,
      });
      return;
    } else if (tipoPersona == 1 && !rutEmpresa) {
      setAlerta({
        msg: "El RUT ingresado no pertenece a una Persona Natural",
        error: true,
      });
      return;
    }

    if (!rutValido) {
      setAlerta({
        msg: "El RUN ingresado no es válido",
        error: true,
      });
    } else {
      setAlerta({});
      const rutSinDv = rut.substring(0, rut.length - 1).replace(/\D/g, "");
      setCargando(true);
      try {
      const URL = `${process.env.REACT_APP_WS_DEP}/existedpe/${peridoInicial}/${rutSinDv}`;
      const resp = await fetch(URL);
      const data = await resp.json();
      if (data[0]?.rut === rutSinDv) {
        localStorage.setItem("run", data[0].rut);
        localStorage.setItem("token", data[0].token);
        setGetDpe(true);

        setDataUsuarioPropuesta(data);

        setCargando(false);
        setEstadoSolicitud(data[0].estado);

        const beneficiarioExiste = await getPropuestaIngresada(
          data[0].rut,
          data[0].periodoDPE
        );
        if (beneficiarioExiste.length) {
          localStorage.setItem("estadoSolicitud", beneficiarioExiste[0].estado);
          localStorage.setItem(
            "estadoPropuesta",
            JSON.stringify(beneficiarioExiste[0])
            );
            // localStorage.setItem("estadoSolicitud", data[0].estado);
            // localStorage.setItem("estadoPropuesta", JSON.stringify(data[0]));
        }
        //     // setEstadoPropuesta(data[0])

            if (
              beneficiarioExiste.length  &&
              (beneficiarioExiste[0].estado === "2" ||
                beneficiarioExiste[0].estado === "3")
            ) {

              // if (beneficiarioExiste[0].estado === "2" || beneficiarioExiste[0].estado === "3") {
                localStorage.removeItem("run");
                localStorage.removeItem("_grecaptcha");
                localStorage.removeItem("serieRun");
                localStorage.removeItem("tipoDePersona");
                localStorage.removeItem("estadoSolicitud");
                localStorage.removeItem("estadoPropuesta");
                localStorage.removeItem("token");

              setAlerta({
                msg: "Estimado (a) Ud. aceptó su propuesta de devolución, cuyo comprobante fue remitido al correo electrónico indicado.",
                error: true,
              });
              setGetDpe(false);
              return;
            }
        if (!usuarioValido && tipoPersona == 2) {

          setAlerta({
            msg: "Debe marcar la validación de No soy un Robot",
            error: true,
          });
          return;
        }
           else if (usuarioValido && tipoPersona == 2) {
              navigate("/propuesta");

              setAlerta({});
              setCargando(false);
            }
          } else {
            setAlerta({
              msg: "Estimado(a), Usted no posee propuesta de Devolución de pagos en excesos masiva. Sin perjuicio de lo anterior, puede realizar una solicitud de pago en excesos individual a través del sitio web o en cualquier Sucursal de Fonasa.",
              error: true,
            });
            // setRut("");
            setCargando(false);
            btnLimpiar = true;

          }
        } catch (error) {
        setCargando(false);
      }
    }
  };

  const { msg } = alerta;

  return (
    <div className="container my-5 layout_principal">
      <div className="row text-center">
        <div className="col-md-12">
          <h2 className=" mt-5">
            <strong> Proceso DPE </strong>
          </h2>
          <div className="my-5">
            <p>
              {/* TODO: MODIFICAR FECHA DE LOS PAGOS */}
              Fonasa inicia el proceso masivo de Devolución de Cotizaciones
              Pagadas en Exceso el cual se extenderá hasta el {fechaCierre}.
            </p>
            <p>
              Infórmate aquí sobre el proceso de Devolución de Pago en Exceso
              (DPE)
            </p>
            <div className="mt-5">
              <a
                href="https://www.fonasa.cl/sites/fonasa/noticia/dpe"
                className="btn btn-primary mb-3 shadow"
                target="_blank"
                rel="noreferrer"
              >
                {" "}
                Información General DPE
              </a>
            </div>
          </div>
        </div>
      </div>
      <hr />
      {cargando && (
        <div className="d-flex justify-content-center">
          <Spinner />
        </div>
      )}

      {calendario.estado ? (
        <>
          <h6 className="text-center my-4">
            Para saber si usted tiene una propuesta de devolución de
            cotizaciones pagadas en excesos (DPE), ingrese los datos que a
            continuación se solicitan.
          </h6>
          <div className="mb-3 d-flex justify-content-center my-4">
            <div className="col-lg-4 col-md-6 col-sm-8 col-12 d-flex justify-content-around">
              <div className="form-check form-check-inline mb-3">
                <input
                  className="form-check-input"
                  type="radio"
                  name="tipoPersona"
                  id="natural"
                  value="1"
                  checked={tipoPersona == 1 ? true : false}
                  onChange={handleTipoPersona}
                  disabled={getDpe}
                />
                <label className="form-check-label" htmlFor="natural">
                  Persona Natural
                </label>
              </div>
              <div className="form-check form-check-inline mb-3">
                <input
                  className="form-check-input"
                  type="radio"
                  name="tipoPersona"
                  id="juridica"
                  value="2"
                  checked={tipoPersona == 2 ? true : false}
                  onChange={handleTipoPersona}
                  disabled={getDpe}
                />
                <label className="form-check-label" htmlFor="juridica">
                  Persona Jurídica
                </label>
              </div>
            </div>
          </div>
          <div className="row justify-content-center">
            <div className="col-12 col-md-8 col-sm-10 ">
              <form onSubmit={handleSubmit}>
                {msg && <Alerta alerta={alerta} />}

                {tipoPersona == 1 ? (
                  <>
                  <LoginClaveunica></LoginClaveunica>
                   {/*
<ul className="nav nav-pills mb-3" id="pills-tab" role="tablist">
  <li className="nav-item" role="presentation">
    <button className="nav-link active" id="pills-home-tab" data-bs-toggle="pill" data-bs-target="#pills-home" type="button" role="tab" aria-controls="pills-home" aria-selected="true">Ingreso con Clave Única</button>
  </li>
  <li className="nav-item" role="presentation">
    <button className="nav-link" id="pills-profile-tab" data-bs-toggle="pill" data-bs-target="#pills-profile" type="button" role="tab" aria-controls="pills-profile" aria-selected="false">Ingreso con RUT</button>
  </li>

</ul>
<div className="tab-content" id="pills-tabContent">
  <div className="tab-pane fade show active" id="pills-home" role="tabpanel" aria-labelledby="pills-home-tab" tabindex="0"> </div>
  <div className="tab-pane fade" id="pills-profile" role="tabpanel" aria-labelledby="pills-profile-tab" tabindex="0">   <div className="mb-3 d-flex justify-content-center ">
                      <div className="col-lg-4 col-md-6 col-sm-8 col-12 mt-5">
                        <label className="form-label fw-bold">
                          <span className="text-danger">* </span>Ingrese RUN-RUT
                        </label>
                        <input
                          type="text"
                          className="form-control shadow-sm"
                          placeholder="Ejemplo: 12345678-9"
                          value={rut}
                          onChange={(e) => setRut(e.target.value)}
                          onKeyDown={(e) => valideKey(e)}
                          minLength="4"
                          maxLength="10"
                          onPaste={bloqueoPegarCopiar}
                          onCut={bloqueoPegarCopiar}
                          onCopy={bloqueoPegarCopiar}
                          disabled={(getDpe || cargando) && true}
                        />
                      </div>
                    </div>
                    {getDpe ? (
                      <>
                        <div className="mb-3 mt-3 d-flex justify-content-center ">
                          <div className="col-lg-4 col-md-6 col-sm-8 col-12">
                            <label className="form-label fw-bold col-lg-10">
                              <span className="text-danger">* </span>N° de Serie
                              / Documento
                            </label>
                            <div className="btn-group col-lg-2 dropend">
                              <button
                                type="button"
                                className="btn btn-primary  mb-2 dropdown-toggle"
                                data-bs-toggle="dropdown"
                                aria-expanded="false"
                              >
                                ?
                              </button>
                              <ul className="dropdown-menu">
                                <div className="container">
                                  <h5>¿Qué es el número de serie?</h5>
                                  <p>
                                    El número de serie corresponde a los dígitos
                                    ubicados en su cédula de identidad (anverso
                                    en la nueva, reverso en la antigua), cédula
                                    de identidad para extranjeros o pasaporte.
                                  </p>
                                  <div className="">
                                    <img
                                      src={ruta}
                                      alt="Si su cédula es nueva, el número de serie se encuentra en el frente."
                                    />
                                  </div>
                                  <div className="">
                                    <img
                                      src={rud}
                                      alt="Si su cédula es antigua, el número de serie se encuentra al reverso."
                                    />
                                  </div>
                                </div>
                              </ul>
                            </div>
                            <input
                              type="text"
                              className="form-control shadow-sm"
                              placeholder="Ejemplo: 123456789"
                              value={numeroSerie}
                              onChange={(e) => setNumeroSerie(e.target.value)}
                              maxLength="10"
                              onPaste={bloqueoPegarCopiar}
                              onCut={bloqueoPegarCopiar}
                              onCopy={bloqueoPegarCopiar}
                              disabled={cargando && true}
                            />
                          </div>
                        </div>
                      </>
                    ) : null}
                    {getDpe ? (
                      <div className="d-grid gap-2 col-12 col-lg-4 col-md-6 col-sm-8 mx-auto mt-2">
                        <button
                          className="btn btn-primary shadow-sm"
                          type="button"
                          onClick={validarSeriecarnet}
                          disabled={cargando && true}
                        >
                          Continuar
                        </button>
                        <button
                          type="button"
                          className="btn btn-secondary shadow-sm"
                          onClick={limpiar}
                          disabled={cargando && true}
                        >
                          Limpiar
                        </button>
                      </div>
                    ) : (
                      <div className="d-grid gap-2 col-12 col-lg-4 col-md-6 col-sm-8 mx-auto mt-2">
                        <button
                          className="btn btn-primary shadow"
                          type="submit"
                          disabled={rut.length < 9 && true}
                        >
                          Consultar
                        </button>
                        {msg ? (
                          <>
                            <button
                              type="button"
                              className="btn btn-secondary shadow-sm"
                              onClick={limpiar}
                            >
                              Limpiar
                            </button>
                          </>
                        ) : null}
                      </div>
                    )}

</div>

</div>
*/}
       

  

<div> <p className="m-4 justify-content-center" >En caso de no tener clave única “Si usted no posee Clave Única, para su obtención ingrese al siguiente Link    <a
                href="https://www.chileatiende.gob.cl/fichas/11331-obtener-la-clave-unicae"
                target="_blank"
                rel="noreferrer"
              >
                {" "}
                Obtener clave 
              </a> </p></div>


                  </>
                ) : null}
                {tipoPersona == 2 ? (
                  <>



                    <div className="mb-3 mt-3 d-flex justify-content-center">
                      <div className="col-lg-4 col-md-6 col-sm-8 col-12">
                        <label className="form-label fw-bold">
                          <span className="text-danger">* </span>Ingrese RUT
                        </label>
                        <input
                          type="text"
                          className="form-control shadow-sm"
                          placeholder="Ejemplo: 12345678-9"
                          value={rut}
                          onChange={(e) => setRut(e.target.value)}
                          onKeyDown={(e) => valideKey(e)}
                          maxLength="10"
                          minLength="4"
                        />
                      </div>
                    </div>
                    <div className="d-grid gap-2 col-12 col-lg-4 col-md-6 col-sm-8 mx-auto mt-2">
                      <button
                        className="btn btn-primary shadow-sm"
                        type="submit"
                        disabled={rut.length < 9 && true}
                      >
                        Consultar
                      </button>
                      {msg && (
                        <>
                          <button
                            type="button"
                            className="btn btn-secondary shadow-sm"
                            onClick={limpiar}
                            disabled={cargando && true}
                          >
                            Limpiar
                          </button>
                        </>
                      )}
                    </div>
                  </>
                ) : null}
                { tipoPersona == 2 ? (
                  <>
                    <div className="d-flex justify-content-center mt-5 mb-3">
                      <ReCAPTCHA
                        ref={captcha}
                        sitekey={process.env.REACT_APP_REACPTCHA}
                        onChange={onChange}
                      />
                    </div>
                    <p className="text-center text-danger ">
                      * Marcar la validación de No soy un robot{" "}
                      <strong>"Despues de llenar todos los datos"</strong>.
                    </p>
                  </>
                ) : null}
              </form>
            </div>
          </div>
        </>
      ) : null}
      {calendario.periodoDPE && calendario.estado === false ? (
        <>
          <PeriodoCerrado />
        </>
      ) : null}
    </div>
  );
};

export default Prelogin;
