import { useEffect, useState } from "react";
import useAppContext from "../hooks/useAppContext";
import { Formik } from "formik";
import { useNavigate } from "react-router-dom";
import { bloqueoPegarCopiar, soloNumeros } from "../helpers/validaRut";
import { envioCorreo, getHtmlCorreo } from "../helpers/envioCorreo";
import Swal from "sweetalert2";
import { crearPDF, toBase64 } from "../helpers/crearPDF";
import { bitacoraTramite } from "../helpers/bitacoraApp";
import { formatearCantidad, formatNumber } from "../helpers/formateaRut";
import Spinner from "./Spinner";

const FormularioCuentaBanco = () => {
  const {
    dataRegion,
    dataComuna,
    dataBancos,
    dataCuentas,
    getDataComunas,
    infoPersona,
    calendario,
    montoTotalUsuario,
    getDataComprobante,
    cargando,
    tipoPersona,
    setCargando,
  } = useAppContext();

  const [codigoRegion, setCodigoRegion] = useState("");
  const [validaModal, setValidaModal] = useState(true);

  const navigate = useNavigate();

  const getDescripcionBanco = (id) => {
    let nombreBanco = dataBancos.find((banco) => banco.codigoPrincipal === id);
    return nombreBanco.descripcion;
  };

  const TIPO_CUENTA = "4";
  let listaCuentas = ''

  if(tipoPersona == 2){
    listaCuentas = dataCuentas.filter((cuenta) => cuenta.codigoPrincipal !== TIPO_CUENTA)
  }
  else{
    listaCuentas = dataCuentas
  }

  let fechaPeriodo = calendario.fechaInicio
    fechaPeriodo = new Date(fechaPeriodo)
          .toLocaleDateString('es-CL', { year:"numeric", month:"long", day:"numeric"}).slice(5).replace("de ", "").toString();
    fechaPeriodo = fechaPeriodo[1].toUpperCase() + fechaPeriodo.substring(2)

  useEffect(() => {
    if (codigoRegion) {
      getDataComunas(codigoRegion);
    }
  }, [codigoRegion]);

  return (
    <>
    {cargando && (
        <div className="d-flex justify-content-center">
          <Spinner />
        </div>
      )}
      <Formik
        initialValues={{
          banco: "",
          tipoCuenta: "",
          cuenta: "",
          confirmaCuenta: "",
          email: "",
          confirmarEmail: "",
          telefono: "",
          direccion: "",
          comuna: "",
        }}
        validate={(valores) => {
          let errores = {};
          //VALIDAR BANCO
          if (!valores.banco) {
            errores.banco = "Este campo es obligatorio";
          }

          //VALIDAR TIPO CUENTA
          if (!valores.tipoCuenta) {
            errores.tipoCuenta = "Este campo es obligatorio";
          }
          //VALIDAR CUENTA
          if (!valores.cuenta) {
            errores.cuenta = "Este campo es obligatorio";
          } else if (!/^\d{1,18}$/.test(valores.cuenta)) {
            errores.cuenta =
              "La cuenta solo debe contener números y máximo 18 caracteres";
          }

          //VALIDAR CONFIRMAR CUENTA
          if (!valores.confirmaCuenta) {
            errores.confirmaCuenta = "Este campo es obligatorio";
          } else if (!/^\d{1,18}$/.test(valores.confirmaCuenta)) {
            errores.confirmaCuenta =
              "La cuenta solo debe contener números y máximo 18 caracteres";
          } else if (valores.cuenta !== valores.confirmaCuenta) {
            errores.confirmaCuenta =
              "Los números ingresados no coinciden con el campo Cuenta";
          }

          //VALIDAR EMAIL
          if (!valores.email) {
            errores.email = "Este campo es obligatorio";
          } else if (
            !/^[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+\.[a-zA-Z0-9-.]+$/.test(
              valores.email
            )
          ) {
            errores.email = "El correo ingesado no es válido";
          }

          //VALIDAR CONFIRMAR EMAIL
          if (!valores.confirmarEmail) {
            errores.confirmarEmail = "Este campo es obligatorio";
          } else if (
            !/^[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+\.[a-zA-Z0-9-.]+$/.test(
              valores.confirmarEmail
            )
          ) {
            errores.confirmarEmail = "El correo ingesado no es válido";
          } else if (valores.email !== valores.confirmarEmail) {
            errores.confirmarEmail =
              "El correo de confirmación debe ser igual al email de contacto";
          }

          //*VALIDAR TELEFONO
          if (!valores.telefono) {
            errores.telefono = "Este campo es obligatorio";
          } else if (!/^\d{7,10}$/.test(valores.telefono)) {
            errores.telefono = "El teléfono ingresado es incorrecto";
          }

          //VALIDAR DIRECCIÓN
          if (!valores.direccion || valores.direccion.length < 3) {
            errores.direccion =
              "El campo dirección es obligatorio y debe tener mínimo 3 caracteres";
          }
          //VALIDAR REGIÓN
          if (codigoRegion === "") {
            errores.codigoRegion = "Este campo es obligatorio";
          }

          //VALIDAR COMUNA
          if (valores.comuna === "") {
            errores.comuna = "Este campo es obligatorio";
          }

          if (Object.entries(errores).length === 0) {
            setValidaModal(false);
          }

          return errores;
        }}
        onSubmit={async (valores, { resetForm }) => {
          const requestBody = {
            rut: parseInt(infoPersona.run),
            direccion: valores.direccion,
            region: codigoRegion,
            comuna: valores.comuna,
            telefono: valores.telefono,
            email: valores.email,
            banco: valores.banco,
            tipoCuenta: valores.tipoCuenta,
            numeroCuenta: valores.cuenta,
            tipoPago: "Depósito Banco",
            montoDevolucion: montoTotalUsuario,
            periodoDevolucion: infoPersona.periodoDPE,
          };

          Swal.fire({
            title:
              "Para finalizar este proceso debe confirmar datos de Devolución.",
            html: `Estimado: ${infoPersona.nombre} ${
              infoPersona.apellPater || ""
            } ${infoPersona.apellMater || ""} 
            <br> RUN-RUT: ${formatNumber(infoPersona.run)}-${infoPersona.dv} 
            <br> Total Devolución: <strong>${formatearCantidad(
              montoTotalUsuario
            )}</strong> 
            <br><br> Banco: ${getDescripcionBanco(valores.banco)} <br>${
              valores.tipoCuenta
            } número: ${valores.cuenta} 
            <br> Correo electrónico: ${valores.email}`,
            icon: "warning",
            showCancelButton: true,
            confirmButtonColor: "#198754",
            cancelButtonColor: "#dc3545",
            cancelButtonText: "Cancelar",
            confirmButtonText: "Confirmar",
          }).then((result) => {
            if (result.isConfirmed) {
              const request = {
                method: "POST",
                headers: { "Content-Type": "application/json" },
                body: JSON.stringify(requestBody),
              };
              let objPDF = {};
              let objBitacora = {};
              const URL = `${process.env.REACT_APP_WS_DEP}/insertaceptada`;
              setCargando(true);
              objBitacora.fechaInicio = new Date();
              fetch(URL, request).then((data) => {
                data.json().then((resp) => {
                  let adjuntos = "";
                  if (resp.status === 500) {
                    navigate("/completadoproceso");
                    setCargando(false);
                  } else {
                    getDataComprobante(
                      parseInt(infoPersona.run),
                      infoPersona.periodoDPE
                    ).then((data) => {
                      objBitacora.data = data;
                      objPDF = {
                        data,
                        infoPersona,
                      };

                      //CREAR PDF
                      const blob = crearPDF(objPDF);
                      toBase64(blob).then((data) => {
                        adjuntos = data;
                        const requestCorreo = {
                          asunto:
                            `Comprobante FONASA DPE Masivo Proceso Marzo 2023 ${fechaPeriodo}`,
                          remitente: "NotificacionFonasa@fonasa.cl",
                          destinatarios: [valores.email],
                          destinatariosConCopia: [],
                          destinatariosConCopiaOculta: [],
                          adjuntos: ["ComprobanteDPE.pdf" + adjuntos],
                          mensaje: getHtmlCorreo(objPDF),
                        };

                        // *ENVIO DE CORREO
                        envioCorreo(requestCorreo);
                        localStorage.removeItem("run");
                        localStorage.removeItem("serieRun");
                      });
                      setCargando(false);
                      objBitacora.fechaFin = new Date();
                      bitacoraTramite(objBitacora);
                    });
                    Swal.fire({
                      icon: "success",
                      title:
                        "Estimado(a). Su solicitud de devolución de cotizaciones ha sido enviada con éxito al correo electrónico registrado. Presione Aceptar para ver resumen de devolución.",
                      confirmButtonColor: "#198754",
                      confirmButtonText: "Aceptar",
                    }).then((result) => {
                      if (result.isConfirmed) {
                        navigate("/propuesta/comprobantepropuesta");
                      }
                    });
                  }
                });
              });
            }
          });
        }}
      >
        {({
          values,
          errors,
          touched,
          handleSubmit,
          handleChange,
          handleBlur,
        }) => (
          <form onSubmit={handleSubmit} autoComplete="off">
            <div className="form-group row mt-4">
              <label htmlFor=".." className="col-sm-3">
                <span className="text-danger">* </span>Banco
              </label>
              <div className="col-sm-7">
                <div className="wrap-form-control shadow-sm">
                  <select
                    className="form-select"
                    name="banco"
                    aria-label=""
                    onChange={handleChange}
                    onBlur={handleBlur}
                  >
                    <option value= ''>Seleccione Banco</option>
                    {dataBancos.map((bancos) => {
                      return (
                        <option
                          key={bancos.codigoPrincipal}
                          value={bancos.codigoPrincipal}
                        >
                          {bancos.descripcion}
                        </option>
                      );
                    })}
                  </select>
                  {touched.banco && errors.banco && (
                    <p className="text-danger mt-2">{errors.banco}</p>
                  )}
                </div>
              </div>
            </div>
            <div className="form-group row mt-4">
              <label htmlFor=".." className="col-sm-3">
                <span className="text-danger">* </span>Tipo de Cuenta
              </label>
              <div className="col-sm-7">
                <div className="wrap-form-control">
                  <select
                    className="form-select shadow-sm"
                    name="tipoCuenta"
                    onChange={handleChange}
                    disabled={values.banco === ''}
                  >
                    <option value=''>Seleccione Tipo de Cuenta</option>
                    {listaCuentas.map((cuenta) => {
                      return (
                        <option key={cuenta.codigoPrincipal}>
                          {cuenta.descripcion}
                        </option>
                      );
                    })}
                  </select>
                  {touched.tipoCuenta && errors.tipoCuenta && (
                    <p className="text-danger mt-2">{errors.tipoCuenta}</p>
                  )}
                </div>
              </div>
            </div>
            <div className="form-group row mt-4">
              <label htmlFor="cuenta" className="col-sm-3">
                <span className="text-danger">* </span>Número de Cuenta
              </label>
              <div className="col-sm-7">
                <input
                  className="form-control shadow-sm"
                  id="cuenta"
                  name="cuenta"
                  type="text"
                  autoComplete="new-password"
                  placeholder="Ingrese el n° de Cuenta"
                  maxLength="18"
                  value={values.cuenta}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  onPaste={bloqueoPegarCopiar}
                  onCut={bloqueoPegarCopiar}
                  onCopy={bloqueoPegarCopiar}
                  onKeyDown={(e) => {
                    soloNumeros(e);
                  }}
                />
                {touched.cuenta && errors.cuenta && (
                  <p className="text-danger mt-2">{errors.cuenta}</p>
                )}
              </div>
            </div>
            <div className="form-group row mt-4">
              <label htmlFor="confirmaCuenta" className="col-sm-3">
                <span className="text-danger">* </span>Confirmar Número de
                Cuenta
              </label>
              <div className="col-sm-7">
                <input
                  className="form-control shadow-sm"
                  id="confirmaCuenta"
                  name="confirmaCuenta"
                  type="text"
                  autoComplete="new-password"
                  maxLength="18"
                  placeholder="Ingrese el n° de Cuenta"
                  value={values.confirmaCuenta}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  onPaste={bloqueoPegarCopiar}
                  onCut={bloqueoPegarCopiar}
                  onCopy={bloqueoPegarCopiar}
                  onKeyDown={(e) => {
                    soloNumeros(e);
                  }}
                />
                {touched.confirmaCuenta && errors.confirmaCuenta && (
                  <p className="text-danger mt-2">{errors.confirmaCuenta}</p>
                )}
              </div>
            </div>
            <div className="form-group row mt-4">
              <label htmlFor="email" className="col-sm-3">
                <span className="text-danger">* </span> Correo Electrónico
              </label>
              <div className="col-sm-7">
                <input
                  className="form-control shadow-sm"
                  id="email"
                  name="email"
                  type="email"
                  autoComplete="new-password"
                  placeholder="Ej.: correo@correo.com"
                  maxLength={40}
                  value={values.email}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  onPaste={bloqueoPegarCopiar}
                  onCut={bloqueoPegarCopiar}
                  onCopy={bloqueoPegarCopiar}
                />
                {touched.email && errors.email && (
                  <p className="text-danger mt-2">{errors.email}</p>
                )}
              </div>
            </div>
            <div className="form-group row mt-4">
              <label htmlFor="email" className="col-sm-3">
                <span className="text-danger">* </span>Confirmar Correo
                Electrónico
              </label>
              <div className="col-sm-7">
                <input
                  className="form-control shadow-sm"
                  id="confirmarEmail"
                  name="confirmarEmail"
                  type="email"
                  autoComplete="new-password"
                  placeholder="Ej.: correo@mail.com"
                  maxLength={40}
                  value={values.confirmarEmail}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  onPaste={bloqueoPegarCopiar}
                  onCut={bloqueoPegarCopiar}
                  onCopy={bloqueoPegarCopiar}
                />
                {touched.confirmarEmail && errors.confirmarEmail && (
                  <p className="text-danger mt-2">{errors.confirmarEmail}</p>
                )}
              </div>
            </div>
            <div className="form-group row mt-4">
              <label htmlFor="telefono" className="col-sm-3">
                <span className="text-danger">* </span>N° de Teléfono +56
              </label>
              <div className="col-sm-7">
                <input
                  className="form-control shadow-sm"
                  id="telefono"
                  name="telefono"
                  type="text"
                  maxLength="10"
                  autoComplete="new-password"
                  placeholder="Ingrese el n° de teléfono sin puntos"
                  value={values.telefono}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  onKeyDown={(e) => {
                    soloNumeros(e);
                  }}
                />
                {touched.telefono && errors.telefono && (
                  <p className="text-danger mt-2">{errors.telefono}</p>
                )}
              </div>
            </div>

            <div className="form-group row mt-4">
              <label htmlFor=".." className="col-sm-3 col-form-label ">
                <span className="text-danger">* </span>Región
              </label>
              <div className="col-sm-7">
                <select
                  className="form-select shadow-sm"
                  name="region"
                  onChange={(e) => {
                    setCodigoRegion(e.target.value);
                  }}
                >
                  <option>Seleccione Región</option>

                  {dataRegion.map((regiones) => {
                    return (
                      <option
                        key={regiones.codigoPrincipal}
                        value={regiones.codigoPrincipal}
                      >
                        {regiones.descripcion}
                      </option>
                    );
                  })}
                </select>
                {!codigoRegion && (
                  <p className="text-danger mt-2">{errors.codigoRegion}</p>
                )}
              </div>
            </div>

            <div className="form-group row mt-4">
              <label htmlFor=".." className="col-sm-3">
                <span className="text-danger">* </span>Comuna
              </label>
              <div className="col-sm-7">
                <div className="wrap-form-control shadow-sm">
                  <select
                    className="form-select"
                    name="comuna"
                    aria-label=""
                    onChange={handleChange}
                  >
                    <option>Seleccione Comuna</option>
                    {dataComuna.map((comunas) => {
                      return (
                        <option
                          key={comunas.codigoPrincipal}
                          value={comunas.codigoPrincipal}
                        >
                          {comunas.descripcion}
                        </option>
                      );
                    })}
                  </select>
                  {touched.comuna && errors.comuna && (
                    <p className="text-danger mt-2">{errors.comuna}</p>
                  )}
                </div>
              </div>
            </div>
            <div className="form-group row mt-4">
              <label htmlFor="direccion" className="col-sm-3">
                <span className="text-danger">* </span> Dirección
              </label>
              <div className="col-sm-7">
                <input
                  className="form-control shadow-sm"
                  id="direccion"
                  name="direccion"
                  type="text"
                  autoComplete="new-password"
                  placeholder=""
                  maxLength="100"
                  value={values.direccion}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  onPaste={bloqueoPegarCopiar}
                  onCut={bloqueoPegarCopiar}
                  onCopy={bloqueoPegarCopiar}
                />
                {touched.direccion && errors.direccion && (
                  <p className="text-danger mt-2">{errors.direccion}</p>
                )}
              </div>
            </div>
            <p className="text-danger pt-3">
              <strong>* Datos obligatorios</strong>
            </p>
            <p className="text-center mt-4">
              <button
                className="btn btn-primary shadow"
                type="submit"
                disabled={validaModal ? true : false}
              >
                Solicitar Devolución
              </button>
            </p>
          </form>
        )}
      </Formik>
    </>
  );
};

export default FormularioCuentaBanco;
