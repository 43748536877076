import { Outlet, Navigate} from 'react-router-dom'
import useAppContext from '../hooks/useAppContext'
import Footer from "../components/Footer";
import Header from "../components/Header";
import Spinner from '../components/Spinner';
import Warning from '../components/Warning';


const RutaProtegida = () => {

  const { dataUsuarioPropuesta, cargando } = useAppContext()
  
  
  // if(cargando) return <div className='contenedor_cargando'><Spinner/></div>

  return (
    <>
      <Header />
      {dataUsuarioPropuesta.length > 0 ? <Outlet/> : <Navigate to="/"/>}
     <Footer />
    </>
  )
}

export default RutaProtegida
