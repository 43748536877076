import { useEffect, useState } from "react";
import useAppContext from "../hooks/useAppContext";
import { formatNumber, formatearCantidad } from "../helpers/formateaRut";
import { Link } from "react-router-dom";
import FormularioValeVista from "../components/FormularioValeVista";
import FormularioCuentaBanco from "../components/FormularioCuentaBanco";
import FormularioCuentaRut from "../components/FormularioCuentaBancoRut";

function Formulariopropuesta() {
  const [valor, setValor] = useState();

  const { infoPersona, montoTotalUsuario, tipoPersona, setTipoPersona } = useAppContext();
  const tipoDePersona = localStorage.getItem('tipoDePersona')
  useEffect(()=> {
    setTipoPersona(tipoDePersona)
  })
  return (
    <>
      <div className="container contenido mb-5">
        <div className="row ">
          <div className="col-md-12 center mT40">
            <hr />
          
            <div className="row">  <strong style={{   width: "40%" ,textAlign:"center",marginBottom:5 }} className="hola " > Paso 1 </strong>{" "}
            <strong style={{   width: "20%",textAlign:"center",marginBottom:5 }} className="hola" > Paso 2 </strong>{" "}</div>
           
              <div className="prueba" style={{  height:"10px" , width: "50%" }}> </div>
             
           
              
              <div className="progress">
              
                <div
                  className="progress-bar "
                  role="progressbar"
                  style={{ width: "50%" }}
                >
                  {" "}
                  <strong>  </strong>{" "}
                </div>
                
              </div>
              <div className="prueba" style={{  height:"10px" , width: "50%" }}> </div>
              <div className="row"><strong style={{   width: "40%",textAlign:"center" }} className="hola" > Propuesta </strong>{" "} <strong style={{   width: "20%",textAlign:"center"}} className="hola" > Formulario Pago </strong>{" "}</div>
              
            <div>
              <div className="d-grid gap-2 d-md-flex justify-content-md-end mt-4">
                <Link
                  to={"/propuesta"}
                  type="button"
                  className="btn btn-secondary shadow"
                >
                  Volver
                </Link>
              </div>
              <div className="text-center">
              <h2 className="fs-4 mt-4">
                Formulario Aceptación Devolución de Pago en Exceso{" "}
              </h2>
                <h3 className="fs-4 mt-4">
                  Estimado(a): {infoPersona.nombre} {infoPersona.apellPater}{" "}
                  {infoPersona.apellMater}{" "}
                </h3>
                <h3 className="fs-4 mt-4">
                  RUN-RUT:  {formatNumber(infoPersona.run)}-{infoPersona.dv}
                </h3>

                <div className="alert alert-primary mt-5 shadow" role="alert">
                  <p className="fs-4">
                    Total Devolución: <strong>{formatearCantidad(montoTotalUsuario)}</strong> 
                  </p>
                </div>

                {/* <h2 className="mt-5 mb-5">
                  SELECCIONE OPCIÓN DE PAGO DE DEVOLUCIÓN
                </h2> */}
                <h4 className="mt-5">
                  Por favor seleccione la opción de pago para que realicemos su
                  devolución.
                </h4>
              </div>
              <div className="mt-5 bd-example shadow">
                <div className="">
                  <div className="form-group mb-5">
                    <div className="radio text-center ">
                      <label className="radio-inline m-2">
                        <input
                          type="radio"
                          name="m_pago"
                          id="inlineRadio1"
                          value="1"
                          checked={valor == 1 ? true : false}
                          onChange={(e) => setValor(e.target.value)}
                        />
                         {" "}  Vale Vista Banco Scotiabank
                      </label>

                      <label className="radio-inline m-2">
                        <input
                          type="radio"
                          name="m_pago"
                          id="inlineRadio2"
                          value="2"
                          checked={valor == 2 ? true : false}
                          onChange={(e) => setValor(e.target.value)}
                        />
                        {" "} {" "}Depósito en Cuenta Corriente - Vista - Ahorro
                      </label>
                      {tipoPersona == 1 ?(
                        <label className="radio-inline m-2">
                        <input
                          type="radio"
                          name="m_pago"
                          id="inlineRadio"
                          value="3"
                          checked={valor == 3 ? true : false}
                          onChange={(e) => setValor(e.target.value)}
                        />
                        {" "} {" "}Depósito en Cuenta Rut Banco Estado
                      </label>
                      ): null}
                    </div>
                  </div>
                  {valor == 1 && (
                    <div id="inlineRadio1">
                      <FormularioValeVista />
                    </div>
                  )}

                  {valor == 2 && (
                    <div id="inlineRadio2">
                      <FormularioCuentaBanco />
                    </div>
                  )}
                  {valor == 3 && (
                    <div id="inlineRadio2">
                      <FormularioCuentaRut />
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Formulariopropuesta;
