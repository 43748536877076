import "../App.css";

const Spinner = () => {
  return (
    <>
    <div className="loader"></div>
      {/* <div className="contenedor-spinner">
        <div className="spinner-border text-primary" role="status">
          <span className="visually-hidden">Cargando...</span>
        </div>
      </div> */}
    </>
    // <div className="spinner">
    //   <div className="rect1"></div>
    //   <div className="rect2"></div>
    //   <div className="rect3"></div>
    //   <div className="rect4"></div>
    //   <div className="rect5"></div>
    //   <div className="rect6"></div>
    //   <div className="rect7"></div>
    //   <div className="rect8"></div>
    //   <div className="rect9"></div>
    //   <div className="rect10"></div>
    // </div>
  );
};

export default Spinner;
