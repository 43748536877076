export const bitacoraTramite = async (obj) => {
  const bitacora = {
    tramite: "DPE_DEVOLUCION",
    aplicacion: "BENEFICIARIOWEB",
    folioTramiteExterno: obj.data.folio,
    fechaInicio: obj.fechaInicio,
    fechaFin: obj.fechaFin,
    resultado: 2,
    runBeneficiario: `${obj.data.rut}-${obj.data.dv}`,
    usuarioAplicacion: `${obj.data.rut}-${obj.data.dv}`,
    tiempoEjecucion: obj.fechaFin.getTime() - obj.fechaInicio.getTime(),
    requestJson: obj.data,
  };
  const request = {
    method: "POST",
    headers: { "Content-Type": "application/json" },
    body: JSON.stringify(bitacora),
  };

  const URL = `${process.env.REACT_APP_WS_DEP}/bitacoratramites`;
  const resp = await fetch(URL, request);
  const data = await resp.json();
  return data
};
