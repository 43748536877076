import { Link } from "react-router-dom";
import useAppContext from "../hooks/useAppContext";
import { formatNumber, formatearCantidad } from "../helpers/formateaRut";
import BotonPdf from "../components/BotonPdf";
import { resetApp } from "../helpers/validaInput";
import ImgCalendario2 from "../img/Calendario_dpe_2024.png"

const ComprobantePropuesta = () => {
  const {
    infoPersona,
    dataUsuarioPropuesta,
    montoTotalUsuario,
    dataComprobante,
  } = useAppContext();

  // const [dataComprobante, setDataComprobante] = useState([]);

  const DEPOSITO_BANCO = "Depósito Banco";
  const DEPOSITO_CUENTA_RUT = "Deposito Cuenta RUT";
  const VALE_VISTA = "Vale Vista";

  const date = new Date();
  const tipoPer =localStorage.getItem("tipoDePersona")
  console.log("tipo ppersona",tipoPer)
  const logout = () => {
    resetApp();
  };
  const logoutCue = () => {
  
    localStorage.removeItem("run");
    localStorage.removeItem("tokenc");
    localStorage.removeItem("periodoDPE");
    window.location.href = process.env.REACT_APP_WS_LOGOUT;
    setTimeout(function () {
      window.location.href = "/";
    }, 1000);
    
  };

  return (
    <>
      <div className="container contenido mb-4">
        <hr />
        <div className="row ">
          <div className="col-md-12 center ">
            <div className="row">
              {" "}
              <strong
                style={{ width: "40%", textAlign: "center" }}
                className="hola "
              >
                {" "}
                Paso 1{" "}
              </strong>{" "}
              <strong
                style={{ width: "22%", textAlign: "center" }}
                className="hola "
              >
                {" "}
                Paso 2{" "}
              </strong>{" "}
              <strong
                style={{ width: "36%", textAlign: "end" }}
                className="hola"
              >
                {" "}
                Paso 3{" "}
              </strong>{" "}
            </div>

            <div className="prueba" style={{ height: "10px", width: "96%" }}>
              {" "}
            </div>

            <div className="progress">
              <div
                className="progress-bar "
                role="progressbar"
                style={{ width: "100%" }}
              >
                {" "}
                <strong> </strong>{" "}
              </div>
            </div>
            <div className="prueba" style={{ height: "10px", width: "96%" }}>
              {" "}
            </div>
            <div className="row">
              <strong
                style={{ width: "40%", textAlign: "center" }}
                className="hola"
              >
                {" "}
                Propuesta{" "}
              </strong>{" "}
              <strong
                style={{ width: "22%", textAlign: "center" }}
                className="hola"
              >
                {" "}
                Formulario Pago{" "}
              </strong>{" "}
              <strong
                style={{ width: "38%", textAlign: "end" }}
                className="hola"
              >
                {" "}
                Comprobante{" "}
              </strong>
            </div>

            <div className=" mt-5">
              <h3>Fecha Emisión: {date.toLocaleDateString()}</h3>
              <h3>Folio: {dataComprobante.folio}</h3>
              <h3 className="fs-4 mt-4 text-center">
                Estimado(a): {dataUsuarioPropuesta[0].nombre}{" "}
                {infoPersona.apellPater} {infoPersona.apellMater}{" "}
              </h3>
              <h3 className="fs-4 mt-4 text-center">
                RUN-RUT: {formatNumber(infoPersona.run)}-{infoPersona.dv}
              </h3>
            </div>

            <div>
              <div className="alert alert-primary text-centermy-4 shadow">
                Hemos recibido su solicitud de Devolución de Cotizaciones
                Pagadas en Excesos.{" "}
                <h3>
                  {" "}
                  <strong>
                    {" "}
                    Devolución:{" "}
                    <strong> {formatearCantidad(montoTotalUsuario)} </strong>
                  </strong>
                </h3>
              </div>

              <div id="respuesta">
                {dataComprobante.tipoPago === VALE_VISTA && (
                  <div
                    className="alert alert-primary mt-5 text-left"
                    role="alert"
                  >
                    <p>
                      Su devolución solicitada será efectuada de acuerdo al
                      calendario correspondiente.
                    </p>
                    <p>
                      El pago se realizará a través de{" "}
                      <strong>
                        {dataComprobante.tipoPago} del Banco Scotiabank
                      </strong>
                      .
                    </p>
                    <p>
                      {" "}
                      El comprobante fue emitido al Correo Electrónico{" "}
                      <strong>{dataComprobante.email}</strong>.
                    </p>
                  </div>
                )}

                {dataComprobante.tipoPago === DEPOSITO_BANCO && (
                  <div
                    className="alert alert-primary mt-5 text-left"
                    role="alert"
                  >
                    <p>
                      Su devolución solicitada será efectuada de acuerdo al
                      calendario correspondiente.
                    </p>
                    <p>
                      El depósito se realizará en el{" "}
                      <strong> {dataComprobante.banco} </strong>, a su{" "}
                      <strong> {dataComprobante.tipoCuenta}</strong>.
                    </p>
                    <p>
                      {" "}
                      El comprobante fue emitido al Correo Electrónico{" "}
                      <strong> {dataComprobante.email}</strong>.
                    </p>
                  </div>
                )}

                {dataComprobante.tipoPago === DEPOSITO_CUENTA_RUT && (
                  <div
                    className="alert alert-primary mt-5 text-left"
                    role="alert"
                  >
                    <p>
                      Su devolución solicitada será efectuada de acuerdo al
                      calendario correspondiente.
                    </p>
                    <p>
                      El depósito se realizará en el{" "}
                      <strong> {dataComprobante.banco} </strong>, a su{" "}
                      <strong> {dataComprobante.tipoCuenta}</strong>.
                    </p>
                    <p>
                      {" "}
                      El comprobante fue emitido al Correo Electrónico{" "}
                      <strong> {dataComprobante.email}</strong>.
                    </p>
                  </div>
                )}
                <div className="calendario_pago">
                  <div className="contenedor_img shadow">
                  <img src={ImgCalendario2} alt={ImgCalendario2}/>
                  </div>
                </div>
              </div>
              {/* <div className="alert alert-dark shadow">
                <h5 className="alert-heading text-danger fw-bold">Importante:</h5>
                <p>
                  Si Ud. quiere conocer la fecha de pago de su devolución,
                  ingrese a la página web de Fonasa/ Proceso Masivo de
                  Devolución de Cotizaciones Pagadas en Excesos/ Información
                  General DPE, donde encontrará publicado el Calendario de Pagos
                  del Proceso.
                </p>
              </div> */}
            </div>
            <div className="d-grid gap-2 d-md-flex justify-content-md-end justify-content-center">
              <div className="">
                <BotonPdf
                  dataComprobante={dataComprobante}
                  respuesta="respuesta"
                />

              </div>
              {tipoPer== 1 && (
        <Link
        to={"/"}
        className="btn btn-md btn-success ms-2"
        type="button"
        onClick={logoutCue}
      >
        Cerrar 
      </Link>
      )}
      {tipoPer==2 && (
        <Link
        to={"/"}
        className="btn btn-md btn-success ms-2"
        type="button"
        onClick={logout}
      >
        Cerrar 
       
      </Link>
      )}

            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ComprobantePropuesta;
