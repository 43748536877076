import { BrowserRouter, Route, Routes } from 'react-router-dom';
import { AppProvider } from './context/AppProvider';

import './App.css';
import Layout from './layout/Layout';
import Prelogin from './pages/Prelogin';
import Propuesta from './pages/Propuesta';
import RutaProtegida from './layout/RutaProtegida';
import Formulariopropuesta from './pages/Formulariopropuesta';
import ComprobantePropuesta from './pages/ComprobantePropuesta';
import CompletadoProceso from './pages/CompletadoProceso';
import ObtenerTokenFonasa from "./hooks/GetToken";
import PaginaNodpe from './pages/PaginaNodpe';
import LogoutClaveunica from './pages/LogoutClaveunica';
/* import FormularioRevision from './pages/FormularioRevision';
import ComprobanteRevision from './pages/ComprobanteRevision'; */




function App() {
  return (
    <BrowserRouter>
    <AppProvider>

      <Routes>
        {/* Rutas Publicas */}
        <Route path='/' element={<Layout/>}>
          <Route index element={<Prelogin/>}/>
          {/* <Route path='/propuesta' element={<Propuesta/>}/> */}
          <Route path='/completadoproceso' element={<CompletadoProceso/>}/>
          <Route path='/paginanodpe' element={<PaginaNodpe/>}/>
          <Route path='/cue/callback' element={<ObtenerTokenFonasa />} />
          <Route path='/cue/logout' element={<LogoutClaveunica />} />
          
        </Route>

        <Route path='/propuesta' element={<RutaProtegida/>}>
          <Route index element={<Propuesta/>}/>
          <Route path='formulario-propuesta' element={<Formulariopropuesta/>}/>
          <Route path='comprobantepropuesta' element={<ComprobantePropuesta/>}/>
         
        </Route>
      </Routes>
    </AppProvider>
    </BrowserRouter>
  );
}

export default App;
