export function formatNumber(montoTotalUsuario) {
  return Intl.NumberFormat().format(montoTotalUsuario);
}

export const formatearCantidad = (cantidad) => {
  return cantidad.toLocaleString("es-CL", {
    style: "currency",
    currency: "CLP",
  });
};

export const formatoPeriodo = (periodo) => {
  let arr = [...periodo];
  let year = arr.slice(0, 4).join("");
  let mes = arr.slice(4, 6).join("");
  let periodoFormateado = `${mes}-${year}`;

  return periodoFormateado;
};

const formateadorFecha = new Intl.DateTimeFormat("es-MX", {
  dateStyle: "long",
});

export const formatoPeriodoNombre = (periodo) => {
  let arr = [...periodo];
  let year = arr.slice(2, 6).join("");
  let mes = arr.slice(0, 2).join("");
  let periodoFormateado = new Date(`${year},${mes}`);
  periodoFormateado = formateadorFecha
    .format(periodoFormateado)
    .slice(4)
    .replace("de ", "")
    .toString();

  periodoFormateado =
    periodoFormateado[1].toUpperCase() + periodoFormateado.substring(2);
  return periodoFormateado;
};

export const formateaNumeroCuenta = (cuenta) => {
  let caracter = 'X'
  let cuentaFormateada = cuenta.slice(0, -3) + caracter.repeat(3) 
  return cuentaFormateada
}
