import { formatearCantidad, formatNumber, formatoPeriodoNombre } from "../helpers/formateaRut";
import LogoF from "../img/logofonasa.png";
import { jsPDF } from "jspdf";

export const crearPDF = (objPDF) => {
  const date = new Date();

  const { nombre, apellPater, apellMater, run, dv, periodoDPE } = objPDF.infoPersona;

  const {
    email,
    estado,
    folio,
    montoDevolucion,
    tipoPago,
    banco,
    tipoCuenta,
    periodoDevolucion,
  } = objPDF.data;

  let tipoSolicitud = "";
  let monto = "";
  let estimado = "";
  let input = "";
  let tipoDePago = "";

  if (apellMater === undefined && apellPater === undefined) {
    estimado = nombre;
  } else {
    estimado = `${nombre} ${apellPater} ${apellMater}`;
  }

  if (tipoPago === "Depósito Banco") {
    tipoDePago = `El depósito se realizará en el ${banco} , a su ${tipoCuenta}.`;
  } else if (tipoPago === "Deposito Cuenta RUT") {
    tipoDePago = `El depósito se realizará en el ${banco} , a su ${tipoCuenta}.`;
  } else {
    tipoDePago = `El pago se realizará a través de ${tipoPago} del Banco Scotiabank.`;
  }

  if (estado === "3") {
    tipoSolicitud = "Devolución de Pago en Excesos";
    monto = formatearCantidad(parseInt(montoDevolucion));
    input = `Su devolución solicitada será efectuada de acuerdo al calendario correspondiente.\n\n${tipoDePago}\n\nEl comprobante fue emitido al Correo Electrónico: ${email}.
      `;
  } else {
    tipoSolicitud = "Revisión DPE";
    monto = "En Revisión";
    input = `Hemos recibido Su solicitud de Revisión Devolución de Pago en Excesos.\n\nEl comprobante fue emitido al Correo Electrónico: ${email}.
      `;
  }

  let doc = new jsPDF();
  doc.addImage(LogoF, "PNG", 15, 15, 45, 15);
  doc.setFont("helvetica", "bold");
  doc.setFontSize(12);
  doc.text(
    `Comprobante de Solicitud de ${tipoSolicitud}`,
    110,
    55,
    null,
    null,
    "center"
  );

  doc.setFont("helvetica", "bold");
  doc.setFontSize(12);
  doc.text(`Proceso DPE Masivo ${formatoPeriodoNombre(periodoDPE)}`, 110, 60, null, null, "center");

  doc.setFont("helvetica", "bold");
  doc.setFontSize(12);
  doc.text(`Folio N°: ${folio}`, 110, 65, null, null, "center");

  doc.setFont("helvetica", "normal");
  doc.setFontSize(12);
  doc.text(`Estimado(a):`, 20, 85, null, null);

  doc.setFont("helvetica", "bold");
  doc.setFontSize(12);
  doc.text(`${estimado}`, 47, 85, null, null);

  doc.setFont("helvetica", "normal");
  doc.setFontSize(12);
  doc.text(`RUN-RUT:`, 20, 92, null, null);

  doc.setFont("helvetica", "bold");
  doc.setFontSize(12);
  doc.text(`${formatNumber(run)}-${dv}`, 43, 92, null, null);

  doc.setFont("helvetica", "normal");
  doc.setFontSize(12);
  doc.text(`Monto Devolución:`, 20, 98, null, null);

  doc.setFont("helvetica", "bold");
  doc.setFontSize(12);
  doc.text(`${monto}`, 57, 98, null, null);

  doc.setFont("helvetica", "normal");
  doc.setFontSize(12);
  doc.text(`Fecha Solicitud:`, 20, 104, null, null);

  doc.setFont("helvetica", "bold");
  doc.setFontSize(12);
  doc.text(`${date.toLocaleDateString()}`, 53, 104, null, null);

  doc.setFont("helvetica", "normal");
  doc.setFontSize(12);
  doc.text(input, 20, 120, null, null);

  doc.setFont("helvetica", "bold");
  doc.setFontSize(12);
  doc.text(
    `Ante consultas, puede llamar al Call Center de FONASA 600 360 3000.`,
    105,
    260,
    null,
    null,
    "center"
  );

  let blob = doc.output("blob");

  return blob;
};

export const toBase64 = (blob) =>
  new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(blob);
    reader.onload = () =>
      resolve(reader.result.replace("data:application/pdf;base64,", ""));
    reader.onerror = (error) => reject(error);
  });
