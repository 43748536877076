import React from 'react'



const LoginClaveunica = () => {
    const codigoGenerado = generarCodigoAleatorio(20);

    function generarCodigoAleatorio(longitud) {
        const caracteres = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789/';
        let codigo = '';

        for (let i = 0; i < longitud; i++) {
            const indiceAleatorio = Math.floor(Math.random() * caracteres.length);
            codigo += caracteres.charAt(indiceAleatorio);
        }
        return codigo;
    }
console.log(process.env.REACT_APP_LOGIN_CLAVE_UNICA)
    const handleClick = () => {
        window.location.href = process.env.REACT_APP_LOGIN_CLAVE_UNICA + codigoGenerado;
    }

    return (
        <>
            <div className="d-flex p-2 ">
           
            </div>
            <div className="d-flex justify-content-center">
        <div className="col-12 col-sm-8 border rounded-2 mt-4 p-3 shadow">
          <h4 className="m-0 py-3 text-center fw-bold">
          DEVOLUCIÓN DE PAGOS EN EXCESO
          </h4>
          <hr />
          <div className="my-5">
            <h5 className="text-center">Ingresar con Clave Única del Estado</h5>
            <a className="btn-cu btn-m btn-color-estandar my-4 mx-auto" onClick={handleClick}>
              <span className="cl-claveunica"></span>
              <span className="text">Iniciar sesión</span>
            </a>
          </div>
        </div>
      </div>
        </>
    );
}

export default LoginClaveunica