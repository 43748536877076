import { useNavigate, Link } from "react-router-dom";
import useAppContext from "../hooks/useAppContext";
import ListadoPropuesta from "./ListadoPropuesta";
import {
  formatNumber,
  formateaNumeroCuenta,
  formatearCantidad,
} from "../helpers/formateaRut";

const Propuesta = () => {
  const { infoPersona, montoTotalUsuario, estadoPropuesta, estadoSolicitud } =
    useAppContext();
  const date = new Date();
  const navigate = useNavigate();
  let titulo = "";


  if (estadoSolicitud === "1") {
    titulo = "Propuesta de Devolución de Cotizaciones Pagadas en Exceso";
  } else if (estadoSolicitud === "4") {
    titulo = `Devolución de Cotizaciones Pagadas en Exceso`;
  }
  const tipoPer =localStorage.getItem("tipoDePersona")

  const logout = () => {
    localStorage.removeItem("run");
    localStorage.removeItem("periodoDPE");
    localStorage.removeItem("_grecaptcha");
    localStorage.removeItem("serieRun");
    localStorage.removeItem("tipoDePersona");
    localStorage.removeItem("estadoSolicitud");
    localStorage.removeItem("estadoPropuesta");
    localStorage.removeItem("tokenc");
    localStorage.removeItem("usuarioValido");
    window.location.reload();
    navigate("/");
  };

  const logoutCue = () => {
  
    localStorage.removeItem("run");
    localStorage.removeItem("tokenc");
    localStorage.removeItem("periodoDPE");
    localStorage.removeItem("tipoDePersona");
    localStorage.removeItem("estadoSolicitud");
    localStorage.removeItem("estadoPropuesta");
    localStorage.removeItem("usuarioValido");
    window.location.href = process.env.REACT_APP_WS_LOGOUT;
    setTimeout(function () {
      window.location.href = "/";
    }, 1000);
  };

  return (
    <>
      <div className="container contenido">
        <div className="row ">
          <div className="col-md-12 center mt-2">
            <div className="">
              <hr></hr>
              {estadoSolicitud !== "4" && (
                <div>
                  <strong
                    style={{
                      width: "40%",
                      textAlign: "center",
                      marginBottom: 5,
                    }}
                    className="hola"
                  >
                    {" "}
                    Paso 1{" "}
                  </strong>{" "}
                  <div
                    className="prueba"
                    style={{ height: "10px", width: "20%" }}
                  >
                    {" "}
                  </div>
                  <div className="progress">
                    <div
                      className="progress-bar "
                      role="progressbar"
                      style={{ width: "20%" }}
                    >
                      {" "}
                      <strong> </strong>{" "}
                    </div>
                  </div>
                  <div
                    className="prueba"
                    style={{ height: "10px", width: "20%" }}
                  >
                    {" "}
                  </div>
                  <strong
                    style={{ width: "40%", textAlign: "center" }}
                    className="hola"
                  >
                    {" "}
                    Propuesta{" "}
                  </strong>{" "}
                </div>
              )}
              <h2 className="fs-4 mt-4 text-center">{titulo}</h2>
              <div className="d-grid gap-2 d-md-flex justify-content-md-end">
              { tipoPer== 1 && (
                 <>
                 <button
                   onClick={logoutCue}
                   className="btn btn-success m-2 shadow"
                   role="tab"
                   data-toggle="tab"
                 >
                   Salir 
                 </button>
               </>
                ) }
                 { tipoPer== 2 && (
                 <>
                 <button
                   onClick={logout}
                   className="btn btn-success m-2 shadow"
                   role="tab"
                   data-toggle="tab"
                 >
                   Salir 
                 </button>
               </>
                ) }
              </div>
            </div>
            <div>
              <h3>Fecha: {date.toLocaleDateString()}</h3>
              <h3 className="fs-4 mt-4 text-center">
                Estimado(a): {infoPersona.nombre} {infoPersona.apellPater}{" "}
                {infoPersona.apellMater}{" "}
              </h3>
              <h3 className="fs-4 mt-4 text-center">
                RUN-RUT: {formatNumber(infoPersona.run)}-{infoPersona.dv}
              </h3>

              {estadoSolicitud !== "4" ? (
                <>
                  <div
                    className="alert alert-primary my-5 shadow "
                    role="alert"
                  >
                    <p className="fs-5 m-0">
                      Su propuesta de Devolución de Cotizaciones Pagadas en
                      Excesos es de{" "}
                      <strong>{formatearCantidad(montoTotalUsuario)}</strong>{" "}
                    </p>
                  </div>
                </>
              ) : (
                <>
                  <div
                    className="alert alert-primary my-5 shadow "
                    role="alert"
                  >
                    {/* <p className="fs-5 m-0">
                  Su devolución de Cotizaciones Pagadas en Exceso es de <strong>{montoTotalUsuario}</strong>, de acuerdo con
                   nuestros registros, se realizó en Cuenta: <strong>{estadoPropuesta.numCuenta}</strong>, del Banco <strong>{estadoPropuesta.nomBanco}</strong>, con fecha <strong>{estadoPropuesta.fechaDeposito}.</strong>
                </p> */}
                    <p className="fs-5 m-0">
                      Su devolución de Cotizaciones Pagadas en Exceso es de{" "}
                      <strong>{formatearCantidad(montoTotalUsuario)}</strong>.
                      De acuerdo con nuestros registros, se realizó el depósito de este monto en su cuenta bancaria N°{" "}
                      <strong>
                        {formateaNumeroCuenta(estadoPropuesta.numeroCuenta)}
                      </strong>
                      , del Banco <strong>{estadoPropuesta.banco}</strong>,
                      con fecha{" "}
                      <strong>{estadoPropuesta.fechaDeposito}.</strong>
                    </p>
                  </div>
                </>
              )}

              <ListadoPropuesta />

              <div className="alert alert-warning text-left shadow">
                {/* <p>
                  Si usted está de acuerdo con la propuesta de Devolución de
                  Cotizaciones Pagadas en Exceso, debe presionar botón “Aceptar
                  Devolución”, con lo cual confirma que los datos proporcionados
                  por Fonasa son correctos.
                </p> */}

                <p>
                  {" "}
                  <i className="fa-solid fa-triangle-exclamation"></i> Si usted
                  posee diferencias respecto del monto publicado como
                  devolución, puede realizar una solicitud de devolución de
                  pagos en excesos individual a través del sitio web de Fonasa o
                  en cualquier sucursal de Fonasa.{" "}
                </p>
              </div>

              <p className="botonera text-center">
                {/* <Link
                  to={"formulariorevision"}
                  className="btn btn-primary m-2 shadow"
                  role="tab"
                  data-toggle="tab"
                >
                  Solicitar Revisión Propuesta
                </Link> */}
                {estadoSolicitud !== "4" && (
                  <>
                    <Link
                      to={"formulario-propuesta"}
                      className="btn btn-success m-2 shadow"
                      role="tab"
                      data-toggle="tab"
                    >
                      Aceptar Devolución
                    </Link>
                  </>
                )}
                 { tipoPer== 1 && (
                 <>
                 <button
                   onClick={logoutCue}
                   className="btn btn-success m-2 shadow"
                   role="tab"
                   data-toggle="tab"
                 >
                   Salir 
                 </button>
               </>
                ) }
                 { tipoPer== 2 && (
                 <>
                 <button
                   onClick={logout}
                   className="btn btn-success m-2 shadow"
                   role="tab"
                   data-toggle="tab"
                 >
                   Salir 
                 </button>
               </>
                ) }
                 
               
              </p>
            </div>
          </div>
        </div>
        <div className="">
          <p>Glosario de Términos</p>
          <hr></hr>

          <div className="accordion shadow" id="accordionExample">
            <div className="accordion-item">
              <h2 className="accordion-header" id="headingOne">
                <button
                  className="accordion-button"
                  type="button"
                  data-bs-toggle="collapse"
                  data-bs-target="#collapseOne"
                  aria-expanded="true"
                  aria-controls="collapseOne"
                >
                  Reajuste
                </button>
              </h2>
              <div
                id="collapseOne"
                className="accordion-collapse collapse show"
                aria-labelledby="headingOne"
                data-bs-parent="#accordionExample"
              >
                <div className="accordion-body">
                  Los montos de devolución son reajustados considerando la U.F.
                  del último día del mes anterior al de publicación del proceso
                  DPE Masivo.{" "}
                </div>
              </div>
            </div>
            <div className="accordion-item">
              <h2 className="accordion-header" id="headingTwo">
                <button
                  className="accordion-button collapsed"
                  type="button"
                  data-bs-toggle="collapse"
                  data-bs-target="#collapseTwo"
                  aria-expanded="false"
                  aria-controls="collapseTwo"
                >
                  Doble Pago
                </button>
              </h2>
              <div
                id="collapseTwo"
                className="accordion-collapse collapse"
                aria-labelledby="headingTwo"
                data-bs-parent="#accordionExample"
              >
                <div className="accordion-body">
                  Empleadores realizan por error pago idéntico duplicado de
                  cotizaciones, para un determinado período.{" "}
                </div>
              </div>
            </div>
            <div className="accordion-item">
              <h2 className="accordion-header" id="headingThree">
                <button
                  className="accordion-button collapsed"
                  type="button"
                  data-bs-toggle="collapse"
                  data-bs-target="#collapseThree"
                  aria-expanded="false"
                  aria-controls="collapseThree"
                >
                  Tope Imponible
                </button>
              </h2>
              <div
                id="collapseThree"
                className="accordion-collapse collapse"
                aria-labelledby="headingThree"
                data-bs-parent="#accordionExample"
              >
                <div className="accordion-body">
                  Cotizantes registran pagos de cotizaciones por sobre el Tope
                  Imponible mensual legal, para un determinado período.{" "}
                </div>
              </div>
            </div>
          </div>
        </div>
        <hr></hr>
      </div>
    </>
  );
};

export default Propuesta;