import { formatearCantidad } from "../helpers/formateaRut";

export const envioCorreo = async (requestCorreo) => {
  const token = localStorage.getItem("token");
  const request = {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
    body: JSON.stringify(requestCorreo),
  };

  try {
    const URL = `${process.env.REACT_APP_WS_ENVIAR_CORREO}`;
    const resp = await fetch(URL, request);
    const data = await resp.json();
  } catch (error) {
    console.log(error);
  }
};

// const requestCorreo = {
//   asunto:
//   `Comprobante FONASA DPE Masivo Proceso`,
//   remitente: "NotificacionFonasa@fonasa.cl",
//   destinatarios: "jhonny.mendoza_ext@fonasa.cl",
//   destinatariosConCopia: [],
//   destinatariosConCopiaOculta: [],
//   adjuntos: [],
//   mensaje: "Hola",
// };

// envioCorreo(requestCorreo)

export const getHtmlCorreo = (objPDF) => {
  const { nombre, apellPater, apellMater, run, dv } = objPDF.infoPersona;

  const { estado, folio, montoDevolucion } = objPDF.data;

  let tipoSolicitud = "";
  let monto = "";
  let estimado = "";

  if (apellMater === undefined && apellPater === undefined) {
    estimado = nombre;
  } else {
    estimado = `${nombre} ${apellPater} ${apellMater}`;
  }

  if (estado === "3") {
    tipoSolicitud = "Devolución de Pago en Excesos";
    monto = formatearCantidad(parseInt(montoDevolucion));
  } else {
    tipoSolicitud = "Revisión DPE";
    monto = "En Revisión";
  }

  return `
  <!DOCTYPE html>
  <html lang="es">
  <head>
    <meta charset="UTF-8" />
    <meta http-equiv="X-UA-Compatible" content="IE=edge" />
    <meta name="viewport" content="width=device-width, initial-scale=1.0" />
    <title>Html_Email</title>
    <link
      rel="stylesheet"
      href="https://cdnjs.cloudflare.com/ajax/libs/font-awesome/6.4.0/css/all.min.css"
      integrity="sha512-iecdLmaskl7CVkqkXNQ/ZH/XLlvWZOJyj7Yy7tcenmpD1ypASozpmT/E0iPtmFIB46ZmdtAc9eNBvH0H/ZpiBw=="
      crossorigin="anonymous"
      referrerpolicy="no-referrer"
    />
    <style>
      * {
        margin: 0;
        padding: 0;
        box-sizing: border-box;
      }
      body {
        background: #e5e6e3;
        font-family: Arial, Helvetica, sans-serif;
      }
      .container {
        background-color: #fff;
        max-width: 750px;
        margin: 0 auto;
      }
      .header {
        text-align: center;
        padding: 20px;
        background: #f3f5f7;

      }

      h2 {
        color: #0d6efd;
        margin: 3%;
      }
      .main {
        background-color: #ebf4f8;
        padding: 20px;
        text-align: center;
      }

      .mensaje {
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
        align-items: center;
      }
      .logo {
        margin: 15px;
        background-image: url("img/logonasa.png");
        background-repeat: no-repeat;
        background-position: center;
      }
      .logo i {
        font-size: 3.5rem;
        font-weight: bold;
        color: #198754;
      }
      .main-mensaje {
        color: #0d6efd;
        font-size: 1.4rem;
        font-weight: bold;
        /* text-align: center; */
      }
      .beneficiario {
        text-align: left;
        margin-left: 10%;
      }
      .beneficiario p {
        margin: 20px;
        font-size: 1.1rem;
      }
      .footer {
        padding: 15px;
        text-align: center;
        background: #f3f5f7;

      }
      .footer p {
        margin: 10px;
      }
    </style>
  </head>
  <body>
    <div class="container">
      <div class="header">
        <!-- <img class="w-50" src="/img/logonasa.png" /> -->
        <h2>Estimado(a), ${estimado}</h2>
      </div>
      <div class="main">
        <div class="mensaje">
          <div class="logo"><i class="fa-regular fa-circle-check"></i></div>
          <div>
            <p class="main-mensaje">
              Su proceso ${tipoSolicitud} ha finalizado con Éxito
            </p>
          </div>
        </div>
        <div class="beneficiario">
          <p>Nombre: <strong>${estimado}</strong></p>
          <p>RUT: <strong>${run}-${dv}</strong></p>
          <p>Total Devolución: <strong>${monto}</strong></p>
          <p>Folio: <strong>${folio}</strong></p>
        </div>
      </div>
      <div class="footer">
        <div>
          <p>
            Puede consultar en el sitio web de Fonasa.cl el calendario de pago.
          </p>
        </div>
        <p>
          Ante consultas, puede llamar al Call Center de FONASA 600 360 3000.
        </p>
        <p><strong>Favor no responder este correo electrónico</strong></p>
      </div>
    </div>
  </body>
</html>

        `;
};
